<template>
    <v-row class="mb-10" dense no-gutters>
        <v-col>
            <v-card outlined tile>
                <v-card-title>
                    <v-text-field :value="form.title" label="Title" @input="updateFormField('title', $event)"/>
                </v-card-title>
                <v-card-subtitle>
                    <v-textarea :value="form.description" label="Description (Optional)" rows="1"
                                @input="updateFormField('description', $event)"/>
                </v-card-subtitle>
            </v-card>
        </v-col>
        <kurcc-form-spacer/>
    </v-row>
</template>

<script>
import {UPDATE_INSPECTION_FORM_FIELD} from "@/modules/app/store/mutation-types";

export default {
    name: "KurccFormHeading",
    components: {
        KurccFormSpacer: () => import('@/modules/forms/components/KurccFormSpacer')
    },
    computed: {
        form() {
            return this.$store.state.forms.form
        }
    },
    methods: {
        updateFormField(field, value) {
            this.$store.commit(UPDATE_INSPECTION_FORM_FIELD, {field, value})
        }
    }
}
</script>
